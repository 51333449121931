/* eslint-disable */

<template>
  <div>
    <div class="vx-row" style="width: 101% !important">
      <div class="vx-col w-6/12">
        <div class="main-border rounded-lg bg-dark overflow-hidden">
          <div class="flex bg-light main-bottom-border p-4">
            <feather-icon icon="SmartphoneIcon" svgClasses="mr-4 self-center" />
            <h3 class="font-bold self-center">Devices Discovered (0)</h3>
          </div>
          <div class="p-5">
            <div>
              <div class="flex justify-between w-full gap-x-6">
                <div class="mb-6 w-full">
                  <h4>IP Address</h4>
                  <vs-input class="w-full mt-2" v-model="pages[currentPage].ipVal" />
                </div>

                <div class="mb-6 w-full">
                  <h4>Role</h4>
                  <v-select
                    placeholder="Select a role from the dropdown"
                    class="w-full mt-2"
                    :options="roleOptions"
                    v-model="pages[currentPage].roleVal"
                  />

                  <small
                    v-if="pages[currentPage].roleMarked && pages[currentPage].roleMarked !== pages[currentPage].roleAnswer"
                    class="ml-1 text-danger font-semibold"
                    >This answer is incorrect</small
                  >
                  <small
                    v-if="pages[currentPage].roleMarked && pages[currentPage].roleMarked === pages[currentPage].roleAnswer"
                    class="ml-1 text-success font-semibold"
                    >This answer is correct</small
                  >
                </div>
              </div>

              <div class="mb-12">
                <h3 class="mb-4">Disable Protocols</h3>
                <div class="grid grid-cols-2 lg:grid-cols-3 md:grid-cols-2 gap-y-4 gap-x-12">
                  <div v-for="item in pages[currentPage].protocols" :key="item.name">
                    <div class="flex items-center justify-between my-2">
                      <h4 class="mr-2 font-bold">{{ item.name }}</h4>
                      <vs-switch color="success" class="ml-2" v-model="item.value" />
                    </div>
                    <small v-if="item.marked && item.answer === true && item.marked === item.answer" class="mt-2 text-success font-semibold"
                      >This answer is correct</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex">
                <vs-button
                  class="shadow-lg mr-2"
                  color="danger"
                  icon="icon-arrow-left"
                  icon-pack="feather"
                  :disabled="currentPage === 0"
                  @click="changePage('previous')"
                  >Previous Page
                </vs-button>
                <vs-button
                  class="shadow-lg"
                  color="success"
                  icon="icon-arrow-right"
                  icon-pack="feather"
                  icon-after
                  :disabled="currentPage === 3"
                  @click="changePage('next')"
                  >Next Page
                </vs-button>
              </div>

              <p class="ml-4 p-2 px-4 rounded-lg bg-light">Page: {{ currentPage + 1 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col w-6/12 bg-dark main-border rounded-lg p-0 overflow-hidden">
        <h3 class="font-bold p-4 main-bottom-border bg-light">NMAP Scan Output</h3>
        <div class="p-4 text-success bg-dark" style="max-height: 50.5vh; overflow: auto">
          Nmap scan report for 10.1.45.65<br />
          Host is up (0.015s latency).<br />
          Not shown: 998 filtered ports <br />
          <br />
          PORT STATE SERVICE VERSION<br />
          22/tcp open ssh CrushFTP sftpd (protocol 2.0)<br />
          8080/tcp open http CrushFTP web interface<br />
          Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closure port<br />
          Device type: general purpose<br />
          Running: Microsoft Windows 7 | 2008<br />
          OS CPE : cpe:/o:microsotf:windows_7 cpe:/o:microsoft:windows_server_2008:r2<br />
          OS details: Microsoft Windows 7 SP1 or Windows Server 2008 R2<br />
          <br />
          Nmap scan report for 10.1.45.66<br />
          Host is up (0.016s latency).<br />
          Not shown: 998 closed ports<br />
          PORT STATE SERVICE VERSION<br />
          25/tcp closed smtp Barracuda Networks Spam Firewall smtpd<br />
          415/tcp open ssl/smtp smtpd<br />
          587/tcp open ssl/smtp smtpd<br />
          443/tcp open ssl/http Microsoft IIS httpd 7.5<br />
          Aggresive OS guesses: Linux 3.16 (90%), OpenWrt Chaos Calmer 15.05 (Linux 3.18) or Designated Driver (Linux 4.1 or 4.4) (89%),
          OpenWrt Kamikaze 7.09 (Linux 2.6.22) (88%),<br />
          Linux 4.5 (88%), Asus RT-AC66U router (Linux 2.6) (88%), Linux 3.16 - 4.6 (88%), OpenWrt 0.9 - 7.09 (linux 2.4..30 - 2.4.34)
          (87%), OpenWrt White Russian 0.9 (Linux 2.4.30) (87%),<br />
          Asus RT-N16 WAP (Linux 2.6) (87%), Asus RT-N66U WAP (Linux 2.6) (87%)<br />
          No exact OS matches for host (test conditions non-ideal)<br />
          <br />
          Running (JUST GUESSING): Microsoft Windows Vista | 7 | 2008 | 8.1 (94%)<br />
          OS CPE: cpe:/o:microsoft:windows_vista::sp2 cpe:/o:microsoft:windows_7::sp1<br />
          cpe:/o:microsoft:windows_server_2008 cpe:/o:microsoft:windows_8.1:r1<br />
          Aggressive OS guesses: Microsoft Windows Vista SP2, Windows 7 SP1, or Windows<br />
          Server 2008 (94%), Microsoft Windows Server 2008 R2 (92%), Microsoft Windows Server 2008 SP2 (90%),<br />
          Microsoft Windows 7 SP1 or Windows Server 2008 R2 (90%), Microsoft Windows 7 SP1 or Windows Server 2008 R2 (90%),<br />
          Microsoft Windows Vista SP0 or SP1, Windows Server 2008 SP1, or Windows 7 (85%), Microsoft Windows 8.1 R1 (85%)<br />
          No exact OS matches for host (test conditions non-ideal).<br />
          Service Info: OS: Windows; CPE: cpe:/0:microsoft:windows<br />
          <br />
          Nmap scan report for 10.1.45.67<br />
          Host is up (0.026s latency).<br />
          Not shown: 991 filtered ports<br />
          PORT STATE SERVICE VERSION<br />
          20/tcp closed ftp-data<br />
          21/tcp open ftp FileZilla ftpd 0.9.39 beta<br />
          22/tcp closed ssh<br />
          80/tcp open http Microsoft IIS httpd 7.5<br />
          443/tcp open ssl/http Microsoft IIS httpd 7.5<br />
          2001/tcp closed dc<br />
          2047/tcp closed dls<br />
          2196/tcp closed unkown<br />
          6001/tcp closed x11:1<br />
          Device type: general purpose<br />
          Running (JUST GUESSING): Microsoft Windows Vista |7|2008|8.1 (94%)<br />
          OS CPL : cpe:/o:microsoft:windows_vista::sp2 cpe:/o:microsoft:windows_7::r1<br />
          cpe:/o:microsoft:windows_8server_2008 cpe: cpe:/o:microsoft:windows_8.1:r1 <br />
          Aggressive OS guesses: Microsoft Windows Vista SP2, Windows 7 SP1, or Windows Server 2008 (94%), Microsoft Windows 7 SP1 or
          Windows Server 2008 R2 (90%), Microsoft Windows Server 2008 (87%) , Microsoft Windows Server 2008 R2 SP1 (86%), Microsoft Windows
          Vista SP0 or SP1, Windows Server 2008 SP1, or Windows 7 (85%), Microsoft Windows 8.1 R1 (85%)<br />
          No exact OS matches for host (test conditions non-ideal).<br />
          Service Info: OS: Windows ; CPE: cpe: /o : microsot:windows<br />
          <br />
          Nmap scan report for 10.1.45.68<br />
          Host is up (0.016s latency).<br />
          Not shown: 999 filtered ports<br />
          PORT STATE SERVICE VERSION<br />
          25/tcp open smtp Barracuda Networks Spam Firewall smtpd<br />
          443/tcp open ssl/http-proxy SonicWall SSL-VPN http proxy<br />
          Warning: OSScan results may be unreliable because we could not find at least 1 open and 1 closed port<br />
          Device type: firewall | general purpose | media device<br />
          Running (JUST GUESSING): Linux 3.X|2.6.X (92%), IPCop 2.X(92%), Tiandy embedded (86%)<br />
          OS CPE: cpe:/0:linux:linux_kernel:2.6.32<br />
          Aggressive OS guesses: IPCop 2 firewall (Linux 3.4) (92%), Linux 3.2 (89%), Linux 2.6.32 (87%), Tiandy NVR (86%)<br />
          No exact OS matches for host (test conditions non-ideal).<br />
          Service Info: Host: barracuda.pnp.root; CPE:<br />
          cpe: barracudanetworks:spam_%26_virus_firewall_600:-<br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      currentPage: 0,
      pages: [
        {
          ipVal: '10.1.45.65',
          roleVal: '',
          roleAnswer: 7,
          roleMarked: null,
          protocols: [
            {
              name: '20 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '21 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '22 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '25 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '80 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '415 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '443 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '587 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2047 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2196 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '6001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '8080 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
          ],
        },
        {
          ipVal: '10.1.45.66',
          roleVal: '',
          roleAnswer: 1,
          roleMarked: null,
          protocols: [
            {
              name: '20 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '21 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '22 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '25 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '80 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '415 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '443 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '587 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2047 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2196 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '6001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '8080 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
          ],
        },
        {
          ipVal: '10.1.45.67',
          roleVal: '',
          roleAnswer: 3,
          roleMarked: null,
          protocols: [
            {
              name: '20 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '21 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '22 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '25 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '80 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '415 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '443 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '587 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2001 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '2047 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '2196 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '6001 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '8080 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
          ],
        },
        {
          ipVal: '10.1.45.68',
          roleVal: '',
          roleAnswer: 6,
          roleMarked: null,
          protocols: [
            {
              name: '20 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '21 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '22 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '25 / TCP',
              value: false,
              answer: true,
              marked: null,
            },
            {
              name: '80 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '415 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '443 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '587 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2047 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '2196 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '6001 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
            {
              name: '8080 / TCP',
              value: false,
              answer: false,
              marked: null,
            },
          ],
        },
      ],

      roleOptions: [
        { id: 1, label: 'Email Server' },
        { id: 2, label: 'Database Server' },
        { id: 3, label: 'Web Server' },
        { id: 4, label: 'Windows Server' },
        { id: 5, label: 'Ubuntu Server' },
        { id: 6, label: 'UTM Appliance' },
        { id: 7, label: 'FTP Server' },
      ],

      //Sim Requirements
      dataLocal: JSON.parse(JSON.stringify(this.data)),
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;
      let simulation_bad = false;

      this.pages.forEach((page, index) => {
        if (page.roleVal.id === page.roleAnswer) {
          this.pages[index].roleMarked = page.roleVal.id;
          totalScore++;
        } else {
          this.pages[index].roleMarked = page.roleVal.id;
        }

        page.protocols.forEach((protocol, pIndex) => {
          if (protocol.answer === false && protocol.value === true) {
            simulation_bad = true;
          }
          if (protocol.answer === true && protocol.value === protocol.answer) {
            this.pages[index].protocols[pIndex].marked = true;
            totalScore++;
          } else {
            this.pages[index].protocols[pIndex].marked = false;
          }
        });
      });

      if (simulation_bad) {
        this.$emit('simulation_getting_there', 0, false);
        return this.$vs.dialog({
          color: 'danger',
          title: 'Invalid Ports Selected!',
          text: 'You selected some of the ports that weren\'t supposed to be checked, try again.',
        });
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
  },
  mounted() {
    this.roleOptions = shuffle(this.roleOptions);
  },
  components: {
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
